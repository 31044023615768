import { useContext } from "react"
import { AuthContext } from "@Context/context/AuthContext"
import { ThemeProvider } from "styled-components"
import { theme } from "@Theme/ThemeUI"
import { Switch } from "react-router-dom"
import MainLayout from "@Components/Theme/Layout/Main/MainLayout"
import DashboardRouter from "@Routes/DashboardRouter"
import GlobalProvider from "@Context/providers/GlobalProvider"

const DashboarContainer = () => {
  const {
    state: { user },
  } = useContext(AuthContext)
  console.log(33)
  const themeApp = user?.mainGroup?.id && theme[user.mainGroup.id as keyof typeof theme]

  return (
    <GlobalProvider>
      <ThemeProvider theme={themeApp || theme.default}>
        <Switch>
          <MainLayout>
            <DashboardRouter />
          </MainLayout>
        </Switch>
      </ThemeProvider>
    </GlobalProvider>
  )
}

export default DashboarContainer
