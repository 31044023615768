import { useRef } from "react"
import { ITagPrintContent } from "@Interfaces/ITagPrintContent"
import AlertStyled from "@Theme/common/Alert.styled"
import { ButtonStyled } from "@Theme/common/Button.styled"
import { IconPrint } from "@Theme/common/Icon.styled"
import QRCode from "react-qr-code"
import TagInfoCustomer from "./TagInfoCustomer"
import TagInfoCustomerStyled, { printStyled } from "./TagPrintContent.styled"
import { useReactToPrint } from "react-to-print"
import Barcode from "@Components/Commons/BarCode"
import useGetSourceInfo from "@Hooks/useGetSourceInfo"
import { TypeVariation6 } from "@Types/CommonsTypes"

// TODO: Temporal. Remove this
import { THEME_CONFIG } from "@Helpers/constHelper"

const TagPrintContent = ({ order, codigoEntrega, shippingGroupId }: ITagPrintContent) => {  
  const website: any = window.$website
  const { sources } = useGetSourceInfo(order.storePickupId)
  const printElement = useRef<HTMLDivElement>(null)

  /* Reference field from wom*/
  const reference = order.shippingAddress?.location?.reference
    ? order.shippingAddress?.location?.reference
    : order.shippingAddress?.custom?.observation

  // TODO: Temporal. Remove this
  let address: string = order.shippingAddress?.address || ""
  let rut: string | TypeVariation6 = undefined
  const locationNumber: string = order.shippingAddress?.location?.number || ""
  const recibeTercero: string | TypeVariation6 =
    order.shippingAddress?.custom?.recibeTercero === "SI"
      ? order.shippingAddress?.custom?.recibeTercero
      : undefined
  let contactCustomName: string | TypeVariation6 = order.shippingAddress?.contact?.name

  if (
    website === THEME_CONFIG.wom.id &&
    locationNumber !== "" &&
    !address.includes(locationNumber)
  ) {
    address = `${order.shippingAddress?.address} ${order.shippingAddress?.location?.number}`
  }

  if (website === THEME_CONFIG.wom.id && recibeTercero === "SI") {
    rut = order.shippingAddress?.custom?.rutTercero
    contactCustomName = 
      `${order.shippingAddress?.custom?.nombreTercero || ""} ${order.shippingAddress?.custom?.apellidoTercero || ""}`
  }

  const handelPrint = useReactToPrint({
    content: () => printElement.current,
    pageStyle: printStyled.toString(),
  })

  const sourceName = sources ? sources[0]?.name : ""

  return (
    <>
      <AlertStyled variant="info">ETIQUETA</AlertStyled>
      <TagInfoCustomerStyled ref={printElement}>
        <TagInfoCustomer
          customerName={order.customer?.name}
          address={address}
          commune={order.shippingAddress?.location?.commune}
          communeName={order.shippingAddress?.location?.communeName}
          reference={reference}
          contactName={
            recibeTercero === "SI" ?
            "" :
            order.shippingAddress?.contact?.name
            }
          contactPhone={order.shippingAddress?.contact?.phone}
          portabilidad={order?.custom?.portabilidad}
          storePickupId={sourceName || order.storePickupId}
          contactRut={rut}
          contactCustomName={contactCustomName}
          recibeTercero={recibeTercero}
        />
        <div className="secundary-section">
          {codigoEntrega && (
            <div className="qr-code">
              <QRCode value={codigoEntrega} size={170} />
            </div>
          )}
          {codigoEntrega && <div className="qr-details">
            <span className="title-code">
              Código de ingreso
            </span>
            <p>{codigoEntrega}</p>
          </div>}

          <Barcode value={shippingGroupId} />

          <ButtonStyled variant="secondary" onClick={handelPrint}>
            <IconPrint />
            <span>Imprimir</span>
          </ButtonStyled>
        </div>
      </TagInfoCustomerStyled>
    </>
  )
}

export default TagPrintContent
