export enum ResourcePermissionsEnum {
  picking = "picking",
  packingElocker = "packing-elocker",
  packingManual = "packing-manual",
  packingGroup = "packing-group",
  qrEntry = "qr-entry",
  qrExit = "qr-exit",
  qrCancel = "qr-cancel",
  printContGuides = "print-cont-guides",
  shipping = "shipping",
  shippingGroup = "shipping-group",
  printGuides = "print-guides",
  delivered = "delivered",
  deliveredSource = "delivered-transit-to-source",
  readyForPickupSource = "pickup-readyforpickup-source",
  pickupElocker = "pickup-elocker",
  pickupManual = "pickup-manual",
  cancelOrder = "cancel-order",
  qrEntryCancel = "qr-entry-cancel",
  qrExitCancel = "qr-exit-cancel",
  deliveredfinal = "delivered-final",
  deliveredfinal2 = "delivered-final-2",
  deliveredfinalcancel = "delivered-final-cancel",
  qrcancel2 = "qr-cancel-2",
  rejecProduct = "reject-product",
  cancelLogistic = "cancel-logistic",
  rejectOrder = "reject-order",
  rejectedOrderStatus = "rejected-order-status",
  finalStatusCourier = "final-status-courier",
  hideConfirmActions = "hide-confirm-actions",
  hideDeliveryActions = "hide-delivery-actions",
  hidePackageActions = "hide-package-actions",
  hidePickupActions = "hide-pickup-actions",
  hideTransitActions = "hide-transit-actions",
  storeCd = "store-cd",
  orderPickupCancel = "order-pickup-cancel",
  orderClosedUploadFiles = "order-closed-upload-files",
  orderDeliveredUploadFiles = "order-closed-upload-files",
  sgDeleteFiles = "sg-delete-files"
}
