import AppRouter from "@Routes/AppRouter"
import AuthProvider from "@Context/providers/AuthProvider"
import { ClientProvider } from "@ContextProviders"
import GlobalStyled from "@Theme/base/GlobalStyled"
import { ThemeProvider } from "styled-components"
import { theme } from "@Theme/ThemeUI"

const AppContainer = () => {
  console.log("tets")
  return (
    <ClientProvider>
      <AuthProvider>
        <ThemeProvider theme={theme.default}>
          <GlobalStyled />
          <AppRouter />
        </ThemeProvider>
      </AuthProvider>
    </ClientProvider>
  )
}

export default AppContainer
